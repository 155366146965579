import { PriceIncludingVat } from 'types/price';

export interface ICampaignCodeValues {
  code: string;
  description: string;
  valid: boolean;
  validationError: string | null;
}

export interface ICampaignDiscountValues {
  name: string;
  discountAmount: PriceIncludingVat;
  code: string;
}

export class CampaignCode {
  code: string;
  description: string;
  valid: boolean;
  validationError: string | null;

  constructor(values: ICampaignCodeValues) {
    this.code = values?.code ?? '';
    this.description = values?.description ?? '';
    this.valid = values?.valid ?? false;
    this.validationError = values?.validationError ?? '';
  }
}

export class CampaignDiscount {
  name: string;
  discountAmount: PriceIncludingVat;
  code: string;

  constructor(values: ICampaignDiscountValues) {
    this.name = values?.name ?? '';
    this.discountAmount = new PriceIncludingVat(values?.discountAmount);
    this.code = values?.code ?? '';
  }
}
