import { removeLogAttribute } from 'helpers/log-helper/log-helper';
import { deleteSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';
import i18next from 'i18next';
import { Cart } from 'types/cart';
import { PriceIncludingVat } from 'types/price';

/**
 * @param preparationMinutes preparationMinutes on Cart.
 * @param showZeroAsAsap If true and preparationMinutes is 0 or undefined,
 * will return translated string "asap".
 * If false will return empty string when preparationMinutes is 0 or undefined.
 * Defaults to true.
 * @returns Returns translated string "X minutes" (default behaviour).
 */
export const getCartPrepTimeDisplayValue = (
  preparationMinutes: number | undefined,
  showZeroAsAsap: boolean = true,
): string => {
  switch (true) {
    case !showZeroAsAsap && !preparationMinutes:
      return '';
    case showZeroAsAsap && !preparationMinutes:
      return i18next.t('time_as_soon_as_possible');
    default:
      return i18next.t('time_x_minute_plural', {
        0: preparationMinutes,
      });
  }
};

export const getTipInCentsDisplayValue = (
  tipAmount: PriceIncludingVat | undefined,
): number | string => {
  if (!tipAmount || tipAmount.cents === 0) return '';
  return tipAmount.cents / 100;
};

/**
 * Get the number of items with id @param articleId in Cart.
 */
export const getArticleQuantity = (
  cart: Cart | undefined,
  articleId: string,
): number =>
  cart?.rows
    .filter((cartRow) => cartRow.article.id === articleId)
    .reduce((acc, curr) => acc + curr.quantity, 0) ?? 0;

/**
 * Deletes all existing references to cart (logging, storage).
 */
export const deleteCartReferences = () => {
  deleteSessionStorage('cartId');
  removeLogAttribute('cartId');
};
