/**
 * Group items in array by shared key value.
 * @param array array of any type of items.
 * @param key key value to group items by.
 */
export const groupBy = (array: any[], key: string): { [key: string]: any[] } =>
  array.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
