import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CountryCode } from 'libphonenumber-js/types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import CustomPhoneInput from 'components/custom-phone-input';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { getPhoneNumberCountryCode } from 'helpers/phone-helper/phone-helper';
import { validatePhoneNumber } from 'helpers/validation-helper/validation-helper';
import { IAccountFormFields } from './AccountForm.types';

interface IPhoneField {
  control: Control<IAccountFormFields, string>;
}

export function PhoneField({ control }: IPhoneField) {
  const { t } = useTranslation();
  const countryCode = (
    document.querySelector(
      'select[name="phoneNumberCountry"]',
    ) as HTMLSelectElement | null
  )?.value as undefined | CountryCode;

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'phoneNumber',
    rules: {
      required: false,
      validate: {
        validPhoneNumber: (v) => validatePhoneNumber(v, countryCode) as string,
      },
    },
  });

  return (
    <FormControl id={field.name} isInvalid={Boolean(error)}>
      <CustomPhoneInput
        id={field.name}
        refCallBack={field.ref}
        isInvalid={Boolean(error)}
        onChange={field.onChange}
        label={
          <FormLabel color={ThemeColorVariable.OnSurface}>
            {t('form_phone_number')}
          </FormLabel>
        }
        autoComplete="tel"
        name={field.name}
        defaultCountry={getPhoneNumberCountryCode(field.value)}
        value={field.value}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
