import { DeliveryType } from 'types/deliveryOption';
import { PriceIncludingVat, PriceType } from 'types/price';

/**
 * @param locale Purposly left undefined as this defaults to the browsers locale (expected behaviour).
 * Provide a fixed value for testing purposes.
 * @returns price as locally formatted string.
 * For ex { cents: 1000, currency: 'SEK' } can be returned as '100 kr'
 * Where the number is fixed to ISO 4217-standard,
 * while the symbol or string value 'kr' is dependent on the current user locale.
 */
export const getLocalPriceFormat = (
  price: PriceIncludingVat,
  locale?: string,
  showCurrency?: boolean,
  forceDecimals?: boolean,
): string => {
  const quotient = Math.floor(price.cents / 100);
  const remainder = price.cents % 100;
  const decimalRepresentation = quotient + remainder / 100;
  let minimumFractionDigits;
  let maximumFractionDigits;

  /*
   * By setting min and max fraction digits based on decimals (when not forced)
   * we can get the following desired output:
   * 9 -> 9 kr
   * 9.9 -> 9.90 kr
   */
  if (!forceDecimals && decimalRepresentation % 1 === 0) {
    minimumFractionDigits = 0;
    maximumFractionDigits = 0;
  } else if (forceDecimals) {
    // Always show two fraction digits when decimals are forced
    minimumFractionDigits = 2;
    maximumFractionDigits = 2;
  }

  if (showCurrency) {
    return (price.cents / 100).toLocaleString(locale, {
      currency: price.currency,
      style: 'currency',
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
  return (price.cents / 100).toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

/**
 * Returns true if price representation in either string (ex. '0,00')
 * or number is considered to be free (not more than 0).
 */
export const isFree = (price: number | string) => {
  if (typeof price === 'number' && price === 0) return true;
  if (typeof price === 'string') {
    const regex = /\d+/g;
    const matches = price.match(regex);
    if (!matches) return false;
    return !matches.some((number) => parseInt(number, 10) > 0);
  }
  return false;
};

export const getPriceTypeFromDeliveryType = (
  deliveryOption: DeliveryType | string | undefined,
): PriceType => {
  switch (deliveryOption) {
    case 'DELIVERY_TO_ADDRESS':
    case 'PICKUP_AT_RESTAURANT':
      return PriceType.TAKEAWAY;
    case 'EAT_AT_RESTAURANT':
    default:
      return PriceType.IN_HOUSE;
  }
};

/**
 * @param locale Purposly left undefined as this defaults to the browsers locale (expected behaviour).
 * Provide a fixed value for testing purposes. Used mostly for testing.
 * @returns Locally formatted currency string from Price.
 * For ex "SEK" (english locale) or "Kr" (swedish locale).
 */
export const getLocalCurrencyString = (
  price?: PriceIncludingVat,
  locale?: string,
) => {
  if (!price) return '';
  const formatted = (price.cents / 100).toLocaleString(locale, {
    currency: price.currency,
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  // Remove numbers (and , . and non breaking spaces) from price string, leaving only unit (currency string)
  const currencyString = formatted
    .replace(/[0-9]+([,.\xa0][0-9]+)?/, '')
    .trim();
  return currencyString;
};
