import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonPlacesList } from 'components/skeletons/SkeletonPlacesList';
import NotFound from 'components/not-found';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { CSSVarColumnWidthLarge } from 'components/global-styles/GlobalStyles';
import { Place } from 'types/place';
import { usePlacesStore } from 'store/placesStore';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { matchesQuery, sortByLocation } from '../../helpers/PlacesPage.helpers';
import { usePlacesRouteRequests } from '../../hooks/usePlacesRouteRequests';
import PlacesList from '../places-list/PlacesList';
import PlacesSearchBar from '../places-search-bar/PlacesSearchBar';

interface PlacesDisplayData {
  searchQuery: string;
  sorted: Place[];
  searchResult: Place[];
}

function PlacesPage() {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { places, hasLoadedPlaces } = usePlacesStore();
  const { isLoading, error } = usePlacesRouteRequests();
  const [displayData, setDisplayData] = useState<PlacesDisplayData | undefined>(
    undefined,
  );

  useEffect(() => {
    // Set initial display data when places have been loaded
    if (hasLoadedPlaces) {
      setDisplayData({
        searchQuery: '',
        sorted: places,
        searchResult: places,
      });
    }
  }, [hasLoadedPlaces]);

  const byQuery = (place: Place) =>
    matchesQuery(place, displayData?.searchQuery.toLowerCase() || '');

  const onSort = async () => {
    if (!displayData) return;

    await sortByLocation(places)
      .then((sortedPlaces) => {
        setDisplayData({
          ...displayData,
          sorted: sortedPlaces,
          searchResult: sortedPlaces.filter(byQuery),
        });
      })
      .catch(() => {
        showToast({
          type: 'error',
          description: t('location_geolocation_turned_off') as string,
        });
      });
  };

  const onClearAndSort = async () => {
    await sortByLocation(places)
      .then((sortedPlaces) => {
        setDisplayData({
          searchQuery: '',
          sorted: sortedPlaces,
          searchResult: sortedPlaces,
        });
      })
      .catch(() => {
        showToast({
          type: 'error',
          description: t('location_geolocation_turned_off') as string,
        });
      });
  };

  const onSearch = (newSearchQuery: string) => {
    if (!displayData) return;

    const searchResult = displayData?.sorted.filter((place) =>
      matchesQuery(place, newSearchQuery.toLowerCase()),
    );

    setDisplayData({
      ...displayData,
      searchResult,
      searchQuery: newSearchQuery,
    });
  };

  if (isLoading || !displayData) return <SkeletonPlacesList />;
  if (error) return <NotFound showWithButton />;

  let content;
  if (hasLoadedPlaces && places.length === 0) {
    // Has fetched places but found nothing
    content = (
      <NotFound
        icon={Icons.Map}
        title={t('location_no_current_restaurants_title')}
        description={t('location_no_current_restaurants_description')}
      />
    );
  } else if (displayData.searchResult.length > 0) {
    // Found places matching search query
    content = <PlacesList places={displayData.searchResult} />;
  } else {
    // No search results found

    // Create a base min height that matches cover image if present,
    // use to prevent layout shifts on smaller screens when switching from place list to empty search results
    const coverImgHeight =
      document.querySelector('img[id=cover]')?.clientHeight;
    const minHeightForSmallScreen = coverImgHeight
      ? `${coverImgHeight}px`
      : 'auto';

    content = (
      <Flex
        direction="column"
        minH={{ base: minHeightForSmallScreen, md: 'auto' }}
      >
        <NotFound
          icon={Icons.Search}
          title={t('misc_no_search_results')}
          description={t('location_search_no_result')}
        />
        <Button variant="primary" onClick={onClearAndSort} leftIcon={Icons.Pin}>
          {t('location_sort')}
        </Button>
      </Flex>
    );
  }

  return (
    <Flex justify="center">
      <Flex
        align="center"
        justify="center"
        direction="column"
        mt={ThemeSpaceVariable.Large}
        px={{ base: ThemeSpaceVariable.Medium, md: ThemeSpaceVariable.None }}
        mb={{ base: ThemeSpaceVariable.Medium, md: ThemeSpaceVariable.X2Large }}
        maxW={{ base: '100%', md: CSSVarColumnWidthLarge }}
        w={{ base: '100%', lg: CSSVarColumnWidthLarge }}
      >
        <PlacesSearchBar
          value={displayData.searchQuery}
          onChange={onSearch}
          onClick={onSort}
        />
        {content}
      </Flex>
    </Flex>
  );
}

export default PlacesPage;
