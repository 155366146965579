import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';

export function ListItemOpeningHoursAndContact({
  onClick,
}: {
  onClick: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <NavigationListButton
        onClick={onClick}
        aria-label={t('misc_hours_and_contact') as string}
      >
        <Flex justifyContent="space-between" gap={ThemeSpaceVariable.Small}>
          <Flex align="center" gap={ThemeSpaceVariable.Small}>
            <Icon
              icon={Icons.CircleInfo}
              size={ThemeIconSizeVariable.Small}
              color={ThemeColorVariable.OnBackground}
            />
            <Text
              color={ThemeColorVariable.OnBackground}
              whiteSpace="break-spaces"
              wordBreak="break-all"
            >
              {t('misc_hours_and_contact')}
            </Text>
          </Flex>
          <Text color={ThemeColorVariable.OnBackground} decoration="underline">
            {t('action_show')}
          </Text>
        </Flex>
      </NavigationListButton>
    </>
  );
}
