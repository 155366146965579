import { Cart, ICartResponse, ICartRowDraft } from 'types/cart';
import { IChangeDeliveryTypeRequest } from 'types/deliveryOption';
import { OfferStatus } from 'types/offer';
import { ApiService } from './Api.service';

// API layer
export class CartAPIService {
  static async setMessageOnCart(
    cartId: string,
    newMessage: string,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/message`, {
      method: 'PUT',
      body: JSON.stringify({
        newMessage,
      }),
    });
  }

  static async setPreferredReadyTimeOnCart(
    cartId: string,
    preferredReadyTime: string | null,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/preferred-ready-time`, {
      method: 'PUT',
      body: JSON.stringify({
        preferredReadyTime,
      }),
    });
  }

  static async createCart(
    menuId: string,
    deliveryOptionType: string,
    resourceId?: string,
    initiatedFromAppLink?: boolean,
    initiatedFromAppLinkId?: string,
  ): Promise<ICartResponse> {
    return ApiService.fetch('carts', {
      method: 'POST',
      body: JSON.stringify({
        menuId,
        resourceId,
        deliveryOptionType,
        initiatedFromAppLink,
        initiatedFromAppLinkId,
      }),
    });
  }

  static async getCart(cartId: string): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}`);
  }

  static async deleteCart(cartId: string): Promise<void> {
    return ApiService.fetch(`carts/${cartId}`, {
      method: 'DELETE',
    });
  }

  static async updateCartRow(
    cartId: string,
    rowId: string,
    row: ICartRowDraft,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/rows/${rowId}`, {
      method: 'PUT',
      body: JSON.stringify({
        ...row,
      }),
    });
  }

  static async setCartDeliveryType(
    cartId: string,
    setDeliveryOptionTypeRequest: IChangeDeliveryTypeRequest,
  ) {
    return ApiService.fetch(`carts/${cartId}/delivery-option-type`, {
      method: 'PUT',
      body: JSON.stringify({ ...setDeliveryOptionTypeRequest }),
    });
  }

  static async addCartRow(
    cartId: string,
    newRow: ICartRowDraft,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/rows`, {
      method: 'POST',
      body: JSON.stringify({
        ...newRow,
      }),
    });
  }

  static async deleteCartRow(
    cartId: string,
    rowId: string,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/rows/${rowId}`, {
      method: 'DELETE',
    });
  }

  static async setTipOnCart(
    cartId: string,
    cents: number,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/tip`, {
      method: 'PUT',
      body: JSON.stringify({
        cents,
      }),
    });
  }

  static async setCampaignCodeOnCart(
    cartId: string,
    code: string | null,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/campaign-code`, {
      method: 'PUT',
      body: JSON.stringify({
        code,
      }),
    });
  }

  static async changeCartUser(cartId: string): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/change-user`, { method: 'PUT' });
  }

  static async setCartOffer(
    cartId: string,
    offerId: string,
    offerStatus: OfferStatus,
  ): Promise<ICartResponse> {
    return ApiService.fetch(`carts/${cartId}/offers/${offerId}`, {
      method: 'PUT',
      body: JSON.stringify({ offerStatus }),
    });
  }
}

// Dto layer
export class CartService {
  static async setMessageOnCart(
    cartId: string,
    newMessage: string,
  ): Promise<Cart> {
    return CartAPIService.setMessageOnCart(cartId, newMessage).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async setPreferredReadyTimeOnCart(
    cartId: string,
    preferredReadyTime: string | null,
  ): Promise<Cart> {
    return CartAPIService.setPreferredReadyTimeOnCart(
      cartId,
      preferredReadyTime,
    ).then((data: ICartResponse) => new Cart(data));
  }

  static async createCart(
    menuId: string,
    deliveryOptionType: string,
    resourceId?: string,
    initiatedFromAppLink?: boolean,
    initiatedFromAppLinkId?: string,
  ): Promise<Cart> {
    return CartAPIService.createCart(
      menuId,
      deliveryOptionType,
      resourceId,
      initiatedFromAppLink,
      initiatedFromAppLinkId,
    ).then((data: ICartResponse) => new Cart(data));
  }

  static async getCart(cartId: string): Promise<Cart> {
    return CartAPIService.getCart(cartId).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async deleteCart(cartId: string): Promise<void> {
    return CartAPIService.deleteCart(cartId);
  }

  static async updateCartRow(
    cartId: string,
    rowId: string,
    row: ICartRowDraft,
  ): Promise<Cart> {
    return CartAPIService.updateCartRow(cartId, rowId, row).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async setCartDeliveryType(
    cartId: string,
    setDeliveryOptionTypeRequest: IChangeDeliveryTypeRequest,
  ): Promise<Cart> {
    return CartAPIService.setCartDeliveryType(
      cartId,
      setDeliveryOptionTypeRequest,
    ).then((data) => new Cart(data));
  }

  static async addCartRow(
    cartId: string,
    newRow: ICartRowDraft,
  ): Promise<Cart> {
    return CartAPIService.addCartRow(cartId, newRow).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async deleteCartRow(cartId: string, rowId: string): Promise<Cart> {
    return CartAPIService.deleteCartRow(cartId, rowId).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async setTipOnCart(cartId: string, cents: number): Promise<Cart> {
    return CartAPIService.setTipOnCart(cartId, cents).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async setCampaignCodeOnCart(
    cartId: string,
    code: string | null,
  ): Promise<Cart> {
    return CartAPIService.setCampaignCodeOnCart(cartId, code).then(
      (data: ICartResponse) => new Cart(data),
    );
  }

  static async changeCartUser(cartId: string): Promise<Cart> {
    return CartAPIService.changeCartUser(cartId).then((data) => new Cart(data));
  }

  static async setCartOffer(
    cartId: string,
    offerId: string,
    offerStatus: OfferStatus,
  ): Promise<Cart> {
    return CartAPIService.setCartOffer(cartId, offerId, offerStatus).then(
      (data) => new Cart(data),
    );
  }
}
