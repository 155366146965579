import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalBody } from '@caspeco/casper-ui-library.components.modal';
import { useAuthAPI, useAuthStore } from 'store/authStore';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { useBSTLUserAPI } from 'store/bstlUserStore';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Spinner } from '@chakra-ui/react';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';
import { isAppWithOptionalSignIn } from 'helpers/app-helper/app-helper';
import { useCartAPI, useCartStore } from 'store/cartStore';
import { LogPrefix } from 'types/logging';
import { logDebug } from 'helpers/log-helper/log-helper';
import { getSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';
import { useOffersAPI } from 'store/offersStore';
import { SignInWithEmailForm } from './SignInWithEmailForm';
import { SignInMethods } from './SignInMethods';
import { SignInModalBecomeBusinessCustomer } from './SignInModalBecomeBusinessCustomer';

interface ISignInState {
  isShowingEmailForm: boolean;
  isShowingBecomeBusinessCustomer: boolean;
  isSigningInWithGoogle: boolean;
  isSigningInWithApple: boolean;
  isLoadingBstlUser: boolean;
}

const initialState: ISignInState = {
  isShowingEmailForm: false,
  isShowingBecomeBusinessCustomer: false,
  isSigningInWithGoogle: false,
  isSigningInWithApple: false,
  isLoadingBstlUser: false,
};

export function SignInModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { placeId, menuId, appAlias } = useParams<
    'placeId' | 'menuId' | 'appAlias'
  >();
  const { showToast } = useToast();
  const { signInWithGoogle, setIsSignInModalOpen } = useAuthAPI();
  const { isCartModalOpen } = useCartStore();
  const { getOffers } = useOffersAPI();
  const { setIsCartModalOpen, changeCartUser } = useCartAPI();
  const { isSignInModalOpen, isSignInModalManuallyOpened } = useAuthStore();
  const { getUser, setIsRegisterUserModalOpen } = useBSTLUserAPI();
  const [signInState, setSignInState] = useState<ISignInState>(initialState);

  const onClose = () => {
    setIsSignInModalOpen(false);
  };

  const resetState = () => {
    setSignInState({ ...initialState });
  };

  useEffect(() => {
    // Modal is open and found no stored reference of the user having seen the modal before
    const isFirstTimeUserSeesSignIn = Boolean(
      isSignInModalOpen && !getLocalStorage<boolean>('hasSeenSignIn'),
    );

    if (isFirstTimeUserSeesSignIn && isAppWithOptionalSignIn()) {
      // Now they have seen sign in, store this info (used not to not prompt them again)
      setLocalStorage('hasSeenSignIn', true);
    }

    return () => {
      // Cleanup
      resetState();
    };
  }, [isSignInModalOpen]);

  const onGoToCheckout = () => {
    // Close mobile cart before leaving route
    if (isCartModalOpen) setIsCartModalOpen(false);
    onClose();
    navigate(`/${appAlias}/${placeId}/${menuId}/checkout${location.search}`);
  };

  const trySettingUserAsCartOwner = async () => {
    // Check for cartId to be transferred to now signed in user
    const cartId = getSessionStorage<string | null>('cartId');
    if (!cartId) return;

    // Change cart owner to now signed in user
    await changeCartUser(cartId);

    if (location.pathname.includes('checkout')) {
      // User signed in on checkout page, get cart offers for now signed in user
      await getOffers(cartId);
    }
  };

  const onSignInWithGoogle = async () => {
    try {
      // First, actually sign in as a Firebase user with Google
      setSignInState({ ...signInState, isSigningInWithGoogle: true });
      const firebaseUser = await signInWithGoogle();

      // Now check for existing BSTL user
      setSignInState({ ...signInState, isLoadingBstlUser: true });
      const existingBstlUser = await getUser(firebaseUser.uid);
      if (!existingBstlUser) {
        // No registered user, needs to finish their registration manually in register modal
        // Close and leave sign in modal
        setIsRegisterUserModalOpen(true);
        onClose();
        return;
      }

      // User is already registered, moving on and checking if they should be set as a cart owner
      await trySettingUserAsCartOwner();
      showToast({
        type: 'success',
        description: t('account_now_signed_in'),
      });

      if (!isSignInModalManuallyOpened) {
        // The only time the sign in modal is NOT being manually opened
        // is when the user is trying to go to checkout, so we can safely send them there after sign in
        onGoToCheckout();
      }

      // Clean up
      resetState();
      onClose();
    } catch {
      showToast({
        type: 'error',
        description: t('error_sign_in_google'),
      });
    }
    setSignInState({ ...signInState, isSigningInWithGoogle: false });
  };

  const onSignInWithEmail = () => {
    setSignInState({
      ...signInState,
      isShowingEmailForm: true,
    });
  };

  const onClickBecomeBusinessCustomer = () => {
    setSignInState({ ...signInState, isShowingBecomeBusinessCustomer: true });
  };

  const onGoBack = () => {
    resetState();
  };

  const onClickContinueAsGuest = () => {
    logDebug(LogPrefix.Auth, `Continuing as guest`);
    if (!isSignInModalManuallyOpened) {
      // The only time the sign in modal is NOT being manually opened
      // is when the user is trying to go to checkout, so we can safely send them there after sign in
      onGoToCheckout();
    } else {
      onClose();
    }
  };

  const {
    isLoadingBstlUser,
    isShowingEmailForm,
    isShowingBecomeBusinessCustomer,
    isSigningInWithGoogle,
  } = signInState;

  let modalContent;

  if (isLoadingBstlUser) {
    modalContent = (
      <ModalBody justifyContent="center">
        <Flex
          gap={ThemeSpaceVariable.Large}
          align="center"
          justify="center"
          direction="column"
          minH="300px"
        >
          <Heading
            textAlign="center"
            as="h2"
            color={ThemeColorVariable.OnSurfaceHeader}
          >
            {t('misc_you_will_be_done_soon')}
          </Heading>
          <Spinner
            size="xl"
            thickness={ThemeBorderVariable.Large}
            color={ThemeColorVariable.OnSurface}
          />
        </Flex>
      </ModalBody>
    );
  } else if (isShowingEmailForm) {
    modalContent = <SignInWithEmailForm onGoBack={onGoBack} />;
  } else if (isShowingBecomeBusinessCustomer) {
    modalContent = <SignInModalBecomeBusinessCustomer onGoBack={resetState} />;
  } else {
    modalContent = (
      <SignInMethods
        isSigningInWithGoogle={isSigningInWithGoogle}
        onSignInWithEmail={onSignInWithEmail}
        onSignInWithGoogle={onSignInWithGoogle}
        onContinueAsGuest={onClickContinueAsGuest}
        onClickBecomeBusinessCustomer={onClickBecomeBusinessCustomer}
      />
    );
  }

  return (
    <Modal isOpen={isSignInModalOpen} onClose={onClose} size="md">
      {modalContent}
    </Modal>
  );
}
