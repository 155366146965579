import { useEffect, useState } from 'react';
import { BreakPoint } from 'types/ui';

/**
 * Returns returns true if the document width is equal to or more than
 * the breakpoint provided, or false if not (min-width media query).
 *
 * @param breakpoint the width used to perform a media query of min-width.
 */

export function useMinWidthMediaQuery(breakpoint: BreakPoint): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${breakpoint})`);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    // We need the try catch here because the media query is not supported for safari 13.1
    // See issue BSTL-1917
    try {
      // Chrome & Firefox
      media.addEventListener('change', listener);
    } catch (e1) {
      try {
        // Safari
        media.addListener(listener);
      } catch (e2) {
        // eslint-disable-next-line no-console
        console.error(e2);
      }
    }
    // We need the try catch here because the media query is not supported for safari 13.1
    // See issue BSTL-1917
    return () => {
      try {
        // Chrome & Firefox
        media.removeEventListener('change', listener);
      } catch (e1) {
        try {
          // Safari
          media.removeListener(listener);
        } catch (e2) {
          // eslint-disable-next-line no-console
          console.error(e2);
        }
      }
    };
  }, [matches, breakpoint]);

  return matches;
}
