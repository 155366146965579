import { prefixKey } from 'helpers/storage-helper/storage-helper';

export type SessionStorageKey =
  | 'cartId'
  | 'selectedSectionId'
  | 'checkoutEmail'
  | 'checkoutPhoneNumber'
  | 'initiatedFromAppLink'
  | 'initiatedFromAppLinkId'
  | 'orderId';

export function getSessionStorage<T>(key: SessionStorageKey): T | null;
export function getSessionStorage<T>(key: SessionStorageKey, otherwise: T): T;
export function getSessionStorage<T>(key: SessionStorageKey): T | null {
  const sessionStorageKey = prefixKey(key);
  const data: string | null = window.sessionStorage.getItem(sessionStorageKey);

  if (data !== null) {
    try {
      return JSON.parse(data).value;
    } catch (e) {
      // Invalid JSON, remove broken data
      window.sessionStorage.removeItem(sessionStorageKey);
      return null;
    }
  }

  return null;
}

export function setSessionStorage(
  key: SessionStorageKey,
  value: unknown,
): void {
  window.sessionStorage.setItem(prefixKey(key), JSON.stringify({ value }));
}

export function deleteSessionStorage(key: SessionStorageKey): void {
  window.sessionStorage.removeItem(prefixKey(key));
}
